/*
$tawk-green: #03a84e;
$tawk-blue: #00a4ed;
$bcn-dark: #004aad !default;
//#004aad
//#0b5ed7
//#0a58ca
$primary-dark: #36669c !default;
$primary: #428bca !default;
$violet: #413e66 !default;
$green1: #007234 !default;
$blue1: #1e5f8d !default;
$blue1-1:#36669c!default;
$blue3: #117fa6 !default;
$blue0: #428bca!default;
$blue2: #0ea2bd !default;
$blue2: #2490ef !default;
$cyan1: #65c9cd !default;
*/
/*$breadcrumb-divider: quote("/");*/
html,
body {
  scroll-behavior: smooth;
  /*position: relative;*/
  height: 100% !important;
}

body {
  font-family: var(--font-stack);
  margin: 0;
  padding: 0;
  /*background-color: #fff;*/
  /*color: #525252;
  display: flex;
  flex-direction: column*/
  display: block !important;
}

/**
 * Elements
 */
/**
 * Form Inputs
 */
input,
select,
textarea,
label {
  font-size: 0.9rem;
}

input,
select {
  border-radius: var(--border-radius-full) !important;
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important;
}

textarea {
  border-radius: var(--border-radius-lg) !important;
}

input[type=checkbox] {
  border-radius: 5px !important;
}

div.attached-file {
  border-radius: 30px !important;
}

.form-control {
  color: #023b6d;
  border: 1px solid rgba(2, 59, 109, 0.2) !important;
  /*border: .1rem solid #F4F5F6;*/
  background-color: var(--light) !important;
  height: 30px;
}

.form-control:focus,
.form-control:hover {
  outline: 0;
  box-shadow: 0 0 10px 0 rgba(2, 59, 109, 0.15);
  border: 1px solid rgba(2, 59, 109, 0.3) !important;
}

.form-control:focus {
  background-color: var(--white) !important;
}

/*.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}*/
div.attached-file,
div.like-disabled-input {
  border-radius: var(--border-radius-xl) !important;
  padding: 0.2rem 0.5rem !important;
}

.frappe-control[data-fieldtype="Attach Image"] .attached-file .btn-xs {
  font-size: 0.7rem;
  padding: 0.175rem 0.4rem !important;
}

.btn {
  border: 0.1em solid transparent;
  border-radius: var(--border-radius-full);
  font-size: 1rem;
  font-weight: 400;
  padding: 0.2rem 0.9rem !important;
  /*transition: .3s;*/
}

.btn:hover {
  box-shadow: 0 0 10px 0 rgba(2, 59, 109, 0.15);
}

.btn-sm {
  font-size: 0.9rem;
  padding: 0.21rem 0.8rem !important;
}

.btn-xs {
  font-size: 0.7rem;
  padding: 0.18rem 0.7rem !important;
}

.btn-lg {
  font-size: 1.1rem;
  padding: 0.6rem 1.1rem !important;
}

.hero-content .btn {
  font-size: 1.2rem;
  padding: 0.7rem 1.4rem !important;
}

/*
 * Topbar
 */
.topbar-container {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  background-color: #fff;
}
.topbar-container nav.topbar {
  font-size: 90%;
}
.topbar-container nav.topbar .nav-link {
  padding: 0.3rem 0;
}
.topbar-container nav.topbar .nav-seperator {
  padding: 0.3rem 0.4rem;
  color: #ccc;
  text-decoration: none;
}

.navbar {
  /* Navbar band */
  /*
  .dropdown-toggle::after {
  	display: inline-block;
  	margin-left: 0.255em;
  	vertical-align: 0.255em;
  	content: "";
  	border-top: 0.3em solid;
  	border-right: 0.3em solid transparent;
  	border-bottom: 0;
  	border-left: 0.3em solid transparent;
  }
  */
  /*.navbar-cta, .btn-login-area {
  	padding: .3rem 1.2rem !important;
  }
  .navbar-cta:hover, .btn-login-area:hover {
  	box-shadow: var(--shadow-base);
  }*/
  /*.navbar-cta:hover {
  	background-color: var(--primary);
  	border-color: var(--primary);
  }*/
  /*.dropdown:hover {
  	.nav-link {
  		background-color: var(--primary-light);
  		border-radius: var(--border-radius-lg);
  		border-bottom-left-radius: 0px !important;
  		border-bottom-right-radius: 0px !important;
  	}
  }*/
  /*.dropdown-item.active, .dropdown-item:active {
  	color: --var(--white);
  	text-decoration: none;
  	background-color: --var(--primary-light);
  }*/
}
.navbar .navbar-brand {
  font-size: var(--font-size-lg);
  /*padding: 0px 0px;*/
}
.navbar .navbar-brand img {
  display: inline-block;
  max-width: 200px;
  max-height: 40px;
  transition: all 0.5s ease;
}
.navbar .navbar-toggler {
  border: none !important;
  outline: none !important;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  stroke: #21282e !important;
}
.navbar .navbar-toggler:focus .navbar-toggler-icon {
  stroke: #909090 !important;
}
.navbar .mr-auto .nav-item {
  margin-left: 0.2rem;
}
.navbar .ml-auto .nav-item {
  margin-left: 0rem !important;
  margin-right: 0.2rem !important;
}
.navbar .nav-link {
  /*color: #4C5A67;*/
  max-height: 40px;
  color: var(--gray-800);
  border-radius: var(--border-radius-full);
}
.navbar .nav-link:hover,
.navbar .nav-link:focus,
.navbar .nav-link.active {
  color: var(--primary);
}
.navbar .nav-link:hover,
.navbar .nav-link:focus {
  background-color: var(--white);
}
.navbar .nav-link.active {
  background-color: var(--light);
  /*border-bottom: 3px solid var(--primary);*/
}
.navbar .nav-link.dropdown-toggle:focus {
  color: var(--gray-800);
  background-color: unset;
}
.navbar .nav-link,
.navbar .navbar-cta,
.navbar .btn-login-area {
  font-size: var(--font-size-base) !important;
  font-weight: 500;
}
.navbar .nav-search-item {
  margin-left: 0.1rem;
}
.navbar .nav-search-item .navbar-search {
  padding-right: 2rem;
  height: 38px;
}
.navbar .btn-login-area {
  color: var(--primary);
  border-color: var(--primary);
}
.navbar #language-switcher {
  margin-right: 0.5rem;
}
.navbar #language-switcher select {
  height: 38px;
  /*text-align: center;*/
  border: none !important;
  max-width: 5rem;
  min-width: 2.7rem;
  padding-right: 0.8rem;
  padding-left: 0.8rem;
}
.navbar .dropdown > .dropdown-menu {
  /*background-color: var(--primary-light);*/
  padding: 0.5rem 0.5rem;
  border: 1px solid var(--light);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-md);
}
.navbar .dropdown-item {
  font-size: 1rem !important;
  padding: 0.4rem 1rem;
  border-radius: var(--border-radius-full);
}
.navbar #website-post-login {
  width: 40px !important;
  height: 40px !important;
  padding: 0px !important;
  border-radius: var(--border-radius-full);
  border: 1px solid #eee;
}
.navbar .nav-avatar,
.navbar .user-image-wrapper,
.navbar .user-image-wrapper .avatar,
.navbar .user-image-wrapper .avatar-frame {
  width: 38px !important;
  height: 38px !important;
  padding: 0px !important;
  margin: 0px !important;
}

.dropdown-submenu a::after {
  top: 1rem;
}

/*
 * Sidebar
 */
.sidebar-group .active,
.sidebar-group-container,
.sidebar-item .active,
.list-unstyled > .sidebar-item .active {
  /* padding: .4rem .8rem; */
  border-radius: var(--border-radius-full) !important;
}

.list-unstyled > .sidebar-item > a {
  padding: 0.4rem 0.75rem;
  margin: 0.1rem 0 !important;
  width: 100%;
}

/*.sidebar-group .sidebar-item:hover,     background-color: #f8f9fa;*/
.sidebar-item:hover {
  border-radius: var(--border-radius-full) !important;
}

/*
 * Cards
 */
.page-card,
.frappe-card {
  border-radius: var(--border-radius-xl) !important;
}

.page-card {
  box-shadow: var(--shadow-lg) !important;
}

/*
 * Breadcrumbs
 */
.page-breadcrumbs {
  position: relative;
  /*width: 100vw;*/
  /*height: 100vh;*/
}

.page-breadcrumbs::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  /* override below lines as requirement */
}

/*.page-breadcrumbs {
	COMMENT IT: background-image: url('/assets/bcnerp_com/images/hero-building.jpg');
	background-repeat: no-repeat;
	background-position: top right;
	background-size: cover;
}*/
.page-breadcrumbs > .breadcrumb-container {
  position: relative;
  margin-top: 0px;
  padding-bottom: 1.75rem;
}
.page-breadcrumbs > .breadcrumb-container .breadcrumb-title,
.page-breadcrumbs > .breadcrumb-container .breadcrumb {
  margin: 0;
  padding: 1.75rem 0;
  padding-bottom: 0;
}
.page-breadcrumbs > .breadcrumb-container .breadcrumb-title {
  font-size: 2.25rem;
  font-weight: 700;
}
.page-breadcrumbs > .breadcrumb-container .breadcrumb a,
.page-breadcrumbs > .breadcrumb-container .breadcrumb span {
  font-size: 1rem;
}

/*
 * Web Footer
 */
.web-footer {
  min-height: 200px;
  padding-top: 3rem;
  padding-bottom: 3rem;
  /*margin-top: 3rem;*/
  background-color: #f8f9fa;
}
.web-footer .footer-logo-extension .footer-logo {
  min-width: 5rem;
  min-height: 1rem;
}
.web-footer .footer-logo-extension .footer-col-left {
  text-align: right;
}
.web-footer .footer-logo-extension .footer-col-right {
  text-align: left;
}
.web-footer .footer-group-links {
  max-height: 20rem !important;
}
.web-footer .footer-group-label {
  font-size: var(--font-size-lg) !important;
}
.web-footer .email-field {
  position: relative;
}
.web-footer .email-field input {
  padding-right: 36px;
}
.web-footer .email-field .field-icon {
  right: 12px;
  top: 10px;
  position: absolute;
  z-index: 2;
}
.web-footer #footer-subscribe-email {
  max-width: 200px !important;
  height: 40px;
  background-color: #fff;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.web-footer #footer-subscribe-button {
  /*height:100%;*/
  height: 40px;
  border: 1px solid rgba(2, 59, 109, 0.2) !important;
  border-radius: var(--border-radius-full) 0 0 var(--border-radius-full) !important;
  /*border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: var(--border-radius-full) !important;
  border-bottom-right-radius: var(--border-radius-full) !important;*/
}

/**
 * Modification by page or data-path
 */
/* Hide navbar items and footer links on login, message, error, 404 page */
body[data-path=update-password] .navbar-nav,
body[data-path=message] .navbar-nav,
body[data-path=error] .navbar-nav {
  visibility: hidden !important;
}
body[data-path=update-password] .web-banner, body[data-path=update-password] .navbar-cta,
body[data-path=message] .web-banner,
body[data-path=message] .navbar-cta,
body[data-path=error] .web-banner,
body[data-path=error] .navbar-cta {
  display: none !important;
}
body[data-path=update-password] .web-footer .footer-logo-extension,
body[data-path=update-password] .web-footer .footer-grouped-links,
body[data-path=update-password] .web-footer .footer-links,
body[data-path=message] .web-footer .footer-logo-extension,
body[data-path=message] .web-footer .footer-grouped-links,
body[data-path=message] .web-footer .footer-links,
body[data-path=error] .web-footer .footer-logo-extension,
body[data-path=error] .web-footer .footer-grouped-links,
body[data-path=error] .web-footer .footer-links {
  display: none !important;
}

body[data-path=login] #language-switcher,
body[data-path=login] .btn-login-area,
body[data-path=update-password] #language-switcher,
body[data-path=update-password] .btn-login-area,
body[data-path=message] #language-switcher,
body[data-path=message] .btn-login-area,
body[data-path=error] #language-switcher,
body[data-path=error] .btn-login-area,
body[data-path="404"] #language-switcher,
body[data-path="404"] .btn-login-area {
  display: none !important;
}

body[data-path=search] .page_content input[name=q] {
  height: 38px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

body[data-path=list] {
  /* Blog LIst */
}
body[data-path=list] .blog-list {
  padding-top: 1rem !important;
}

body[data-path^=blog] {
  /* Blog Header */
}
body[data-path^=blog] .blog-header {
  margin-top: 2rem !important;
  margin-bottom: 3rem !important;
}

/* ERPNext: Homepage */
body[data-path=home] .hero-section {
  min-height: 360px;
  /*h1 {
  	font-weight: 400;
  }
  h2 {
  	font-weight: 500;
  }
  h3 {
  	font-weight: 600;
  }*/
}
body[data-path=home] .hero-section h1,
body[data-path=home] .hero-section h2,
body[data-path=home] .hero-section h3 {
  margin-top: 2rem !important;
}
body[data-path=home] .hero-section .btn-primary {
  font-size: 1.1rem;
  font-weight: 400;
  padding: 0.6rem 1.8rem !important;
}

/* ERPNext Products page */
body[class=product-page] button[id=image-view],
body[class=product-page] button[id=list] {
  padding: 0.325rem 0.8rem !important;
}

/* Login Page */
.for-login,
.for-forgot,
.for-signup,
.for-email-login {
  padding: max(12vh, 20px) 0 !important;
}
.for-login .page-card-head img,
.for-forgot .page-card-head img,
.for-signup .page-card-head img,
.for-email-login .page-card-head img {
  max-height: 40px !important;
  /*margin-bottom: 0px !important;*/
  /*margin-bottom: var(--margin-lg, 30px) !important;*/
}
.for-login .page-card-head h4,
.for-forgot .page-card-head h4,
.for-signup .page-card-head h4,
.for-email-login .page-card-head h4 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.for-login .page-card-body .field-icon,
.for-forgot .page-card-body .field-icon,
.for-signup .page-card-body .field-icon,
.for-email-login .page-card-body .field-icon {
  right: 11px !important;
  top: 6px !important;
}
.for-login .page-card-body .toggle-password,
.for-forgot .page-card-body .toggle-password,
.for-signup .page-card-body .toggle-password,
.for-email-login .page-card-body .toggle-password {
  left: 11px !important;
  top: 6px !important;
}

/* My Account Page */
.account-info {
  border-radius: var(--border-radius-xl) !important;
}

/*
 * Web Form
 */
[data-doctype="Web Form"] .page_content .web-form-container {
  /*border: 1px solid var(--dark-border-color);*/
  border-radius: var(--border-radius-xl);
}
[data-doctype="Web Form"] .page_content .web-form-container .web-form-header {
  padding: 0 2rem 2rem;
}

/*
 * Web Template Sections
 */
/* Hero */
main .hero-section {
  min-height: 480px;
  padding-top: 40px;
  padding-bottom: 120px;
}

section[data-section-template=Hero] {
  position: relative;
  width: 100%;
  min-height: 400px;
  /*background-size: cover;
  background-position: 50%;*/
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  box-sizing: border-box;
}
section[data-section-template=Hero] .container {
  padding-top: max(10vh, 50px);
  padding-bottom: max(10vh, 30px);
  z-index: 1;
}
section[data-section-template=Hero] .hero-title {
  margin-bottom: 1rem;
}
section[data-section-template=Hero] .hero-subtitle {
  margin-bottom: 2rem;
}
section[data-section-template=Hero] .hero-buttons .btn {
  font-size: 1.1rem;
  font-weight: 400;
  /*padding: .9rem 2.1rem !important;*/
  box-shadow: var(--shadow-base) !important;
}

section[data-section-template=Hero]::before {
  /*.session-hero-full-screen::before {*/
  content: "";
  /*background-image: url('/files/hero-background.jpg');*/
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center top;
  /*background-size: cover;*/
  background-size: 100% auto;
  background-color: inherit;
  /*opacity:0.6;*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  z-index: 1;
}

section[data-section-template=Hero].hero-full-screen {
  height: 100vh;
}

section[data-section-template=Hero].hero-full-screen::before {
  opacity: 0.8;
}

/* CTA */
.section-cta-container .section-cta {
  box-shadow: var(--shadow-xl);
}
.section-cta-container .confetti-1 {
  background-color: #007234;
}
.section-cta-container .confetti-2 {
  background-color: #f9a602;
}
.section-cta-container .confetti-3 {
  background-color: #eb1c22;
}

/* Split Section with Image */
.split-section-with-image .split-section-image {
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-lg);
  opacity: 0.8;
  transition: all 0.5s ease;
}
.split-section-with-image .split-section-image:hover {
  box-shadow: var(--shadow-xl);
  opacity: 1;
  transition: all 0.5s ease;
}

/*
 * Utilities
 */
/* Scroll Utilities */
.smart-scroll {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
}

.scrolled-up {
  transform: translateY(-100%);
  transition: all 0.5s ease-in-out;
}

.scrolled-down {
  transform: translateY(0);
  transition: all 0.5s ease-in-out;
}

/* Fade in / out animation */
.fade-in {
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
}

.fade-in-1 {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

.fade-out {
  animation: fadeOut 5s;
  -webkit-animation: fadeOut 5s;
  -moz-animation: fadeOut 5s;
  -o-animation: fadeOut 5s;
  -ms-animation: fadeOut 5s;
}

.fade-out-1 {
  animation: fadeOut 1s;
  -webkit-animation: fadeOut 1s;
  -moz-animation: fadeOut 1s;
  -o-animation: fadeOut 1s;
  -ms-animation: fadeOut 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* SVG icons */
.icon-svg-inverted use { /* safari 6.0 - 9.0 */
  filter: invert(100%);
}

.navbar-toggler-icon {
  width: 32px;
  height: 32px;
}

/* Overlay */
#overlay {
  background-color: #eee;
  opacity: 0.3;
  position: fixed;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 100000;
}

.overlay-dark:before {
  background-color: #333c4e;
}

/* Custom scrollbar style for Chrome */
::-webkit-scrollbar {
  width: 11px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.4);
  background: var(--light);
}

/**
 * RESPONSIVE
 */
/*
 * Large Devices (between 1200px to 992px)
 */
/* Large devices and down(laptops/desktops, 1200px and down) */
@media (max-width: 1200px) {
  /* Hero */
  section[data-section-template=Hero].hero-full-screen::before {
    background-position: left top;
    background-size: auto 100%;
    opacity: 0.9;
  }
  /*section[data-section-template="Hero"].hero-full-screen .container {
  	padding-top: max(20vh,70px);
  	padding-bottom: max(10vh,30px);
  }*/
}
/* Large devices and up (laptops/desktops, 992px and up) */
@media (min-width: 992px) {
  section[data-section-template=Hero] .hero-title {
    line-height: 1.3 !important;
  }
}
/*
 * Medium Devices (between 768px to 991px)
 */
/* Medium devices and down (landscape tablets, 991px and down) */
@media (max-width: 991px) {
  /* Hero */
  section[data-section-template=Hero].hero-full-screen::before {
    opacity: 0.7;
  }
  .web-footer {
    padding: 3rem 1rem;
  }
  /* Hide navbar items and footer links on login page */
  body[data-path=login] .navbar-nav,
  body[data-path=update-password] .navbar-nav,
  body[data-path=error] .navbar-nav,
  body[data-path=message] .navbar-nav,
  body[data-path="404"] .navbar-nav {
    display: none !important;
  }
  body[data-path=login] .navbar-cta,
  body[data-path=update-password] .navbar-cta,
  body[data-path=error] .navbar-cta,
  body[data-path=message] .navbar-cta,
  body[data-path="404"] .navbar-cta {
    margin-right: auto !important;
  }
  /* ERPNext Homepage */
  body[data-path=home] section {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}
/* Medium devices and up (landscape tablets, 768px and up) */
@media (min-width: 768px) {
  #language-switcher, .navbar-cta, .btn-login-area {
    margin-right: 0.8rem;
  }
  .navbar {
    /* Dropdown menu on hover */
  }
  .navbar .dropdown > .dropdown-menu {
    margin-top: 0;
  }
  .navbar .dropdown:hover > .dropdown-menu {
    display: block;
  }
  .navbar .dropdown > .dropdown-toggle:active {
    pointer-events: none;
  }
  .navbar .dropdown:hover .nav-link {
    color: var(--primary-color);
  }
  .container > .sidebar-column {
    display: block;
  }
  #website-post-login {
    margin-right: 0.6rem !important;
    margin-left: 0 !important;
  }
}
/*
 * Small Devices (between 600px to 767px)
 */
/* Small devices and down (portrait tablets and large phones, 767px and down) */
@media (max-width: 767px) {
  /* Hero */
  section[data-section-template=Hero].hero-full-screen::before {
    opacity: 0.6;
  }
  /* ERPNext Homepage */
  body[data-path=home] section {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .account-info {
    padding: 1rem 1rem !important;
  }
  /* Navbar */
  .nav-avatar {
    width: 100% !important;
  }
  #search-container, #language-switcher {
    width: 100% !important;
  }
  #language-switcher, #website-post-login, .navbar-cta, .btn-login-area {
    margin-top: 0.8rem;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  #language-switcher select {
    text-align: right !important;
    max-width: 100% !important;
  }
  .web-footer {
    padding: 2rem 0rem;
  }
  .web-footer .ql-editor p {
    padding-bottom: 0;
  }
  .web-footer .footer-logo-extension .footer-logo {
    -o-object-position: center;
       object-position: center;
  }
  .web-footer .footer-logo-extension .footer-col-left, .web-footer .footer-logo-extension .footer-col-right {
    text-align: center !important;
  }
  .web-footer .footer-logo-extension .input-group {
    justify-content: center;
  }
  .web-footer .footer-grouped-links .footer-group-label,
  .web-footer .footer-grouped-links .footer-group-links {
    text-align: center !important;
  }
  .web-footer .footer-links .footer-col-left, .web-footer .footer-links .footer-col-right {
    text-align: center !important;
    padding-bottom: 0;
  }
  .web-footer .footer-links .footer-link {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .web-footer .footer-info .footer-col-left, .web-footer .footer-info .footer-col-right, .web-footer .footer-info .ql-editor p {
    text-align: center !important;
    padding-bottom: 0;
  }
  .web-form-wrapper .form-column:first-child {
    padding-right: 0;
    padding-left: 0.5rem;
  }
  .web-form-wrapper .form-column:last-child {
    padding-right: 0.5rem;
    padding-left: 0;
  }
}
/* Small devices and up (portrait tablets and large phones, 600px and up) */
/*
 * Extra Small Devices (between 576px to 599px)
 */
/* Extra small devices (phones, 599px and down) */
@media (max-width: 599px) {
  /*.navbar-cta, .btn-login-area {
  	width: 100% !important;
  	display: block;
  }*/
  /* Hero */
  section[data-section-template=Hero].hero-full-screen::before {
    opacity: 0.4;
  }
  section[data-section-template=Hero] .hero-buttons .btn {
    font-size: 1rem;
    padding: 0.7rem 0.9rem !important;
  }
  /* ERPNext Homepage */
  body[data-path=home] .hero-section {
    padding-right: 0;
    padding-left: 0;
  }
  .web-form-wrapper .form-column {
    padding: 0;
  }
  .account-info {
    background-color: transparent !important;
    padding: 0 0 !important;
    border: 0 !important;
    box-shadow: none !important;
  }
  /* ERPNext Product Page */
  body[class=product-page] div[id=product-listing] input[id=search-box] {
    max-height: 41px;
  }
  body[class=product-page] div[id=product-listing] button[id=image-view],
  body[class=product-page] div[id=product-listing] button[id=list] {
    width: 41px;
    height: 41px;
    padding: 0.325rem !important;
  }
}
/* Extra small devices and up (phones, 576px and up) */
@media (min-width: 576px) {
  /* Web Form */
  /*[data-doctype="Web Form"] .page-content-wrapper {
  	main.container {
  		border-radius: var(--border-radius-xl) !important;
  		box-shadow: var(--shadow-lg) !important;
  	}
  }*/
}
/*
 * Extra, Extra Small Devices (upto 575px)
 */
/* Extra, Extra small devices (phones, 575px and down) */
@media (max-width: 575px) {
  .navbar .navbar-brand {
    font-size: 1.1rem;
  }
  body[data-path=login] .page-card,
  body[data-path=update-password] .page-card {
    box-shadow: none !important;
  }
  .web-footer {
    padding: 1rem 0rem;
  }
}
:root {
  --border-radius-xl: 16px;
  --border-radius-2xl: 32px;
  --card-border-radius: 16px;
  --shadow-xl: 0px 24px 32px rgba(25, 39, 52, 0.15), 0px 1px 10px rgba(0, 0, 0, 0.06), 0px 0.5px 5px rgba(25, 39, 52, 0.02);
  --font-size-xs: 0.8rem;
  --font-size-sm: 0.9rem;
  --font-size-base: 1rem;
  --font-size-lg: 1.25rem;
  --font-size-xl: 1.5rem;
  --font-size-2xl: 1.75rem;
  --font-size-3xl: 2rem;
  --font-size-4xl: 2.5rem;
  --font-size-5xl: 3rem;
  --font-size-6xl: 3.75rem;
}